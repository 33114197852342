// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dash-js": () => import("./../src/pages/dash.js" /* webpackChunkName: "component---src-pages-dash-js" */),
  "component---src-pages-discovery-call-js": () => import("./../src/pages/discovery-call.js" /* webpackChunkName: "component---src-pages-discovery-call-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logged-in-js": () => import("./../src/pages/logged-in.js" /* webpackChunkName: "component---src-pages-logged-in-js" */),
  "component---src-pages-results-js": () => import("./../src/pages/results.js" /* webpackChunkName: "component---src-pages-results-js" */)
}

